import axios from 'axios';

export const getInstance = (pawshakeWorldRegion) => {
    return axios.create({
        baseURL: __CONFIG__.pawshakeWorldRegionApiUrls[pawshakeWorldRegion],
    });
};

export const getInstanceNewApi = () => {
    return axios.create({
        baseURL: process.env.NEXT_PUBLIC_API_V2_URL,
    });
};
