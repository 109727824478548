import jwtDecode from 'jwt-decode';
import { getInstance, getInstanceNewApi } from '../Pawshake/Core/PawshakeWorldRegionApi';

export class CoreApi {
    getWorldRegionBasedUponJwtToken = (jwtToken) => {
        const decoded = jwtDecode(jwtToken);

        if (typeof decoded.wr === 'undefined') {
            return decoded.pwr;
        }

        return decoded.wr;
    };

    performAuthenticatedGetRequestToSpecificWorldRegion = (worldRegion, jwtToken, url) => {
        return this.performGetRequest(worldRegion, url, {
            headers: {
                authorization: `Bearer ${jwtToken}`,
            },
        });
    };

    performAuthenticatedGetRequest = (jwtToken, url) => {
        return this.performGetRequest(this.getWorldRegionBasedUponJwtToken(jwtToken), url, {
            headers: {
                authorization: `Bearer ${jwtToken}`,
            },
        });
    };

    performAnonGetRequest = (wr, url) => {
        return this.performGetRequest(wr, url);
    };

    sendCommandToSpecificWorldRegion = (worldRegion, jwtToken, commandName, payload) => {
        return this.performPostRequest(
            worldRegion,
            'command/dispatch',
            {
                commandName,
                payload,
            },
            {
                headers: {
                    authorization: `Bearer ${jwtToken}`,
                },
            }
        );
    };

    sendCommand = (jwtToken, commandName, payload) => {
        return this.performPostRequest(
            this.getWorldRegionBasedUponJwtToken(jwtToken),
            'command/dispatch',
            {
                commandName,
                payload,
            },
            {
                headers: {
                    authorization: `Bearer ${jwtToken}`,
                },
            }
        );
    };

    sendAnonCommand = (wr, commandName, payload) => {
        return this.performPostRequest(wr, 'command/dispatch', {
            commandName,
            payload,
        });
    };

    sendAnonQueueCommand = (wr, commandName, payload) => {
        return this.performPostRequest(wr, 'command/queue', {
            commandName,
            payload,
        });
    };

    performPostRequest = (wr, url, payload, config = {}) => {
        return getInstance(wr)
            .post(url, payload, config)
            .then((response) => {
                return {
                    status: response.status,
                    data: response.data,
                };
            })
            .catch((error) => {
                if (error.message && error.message === 'Network Error') {
                    return {
                        status: 503,
                        data: error.message,
                    };
                } else if (!error || !error.response) {
                    return {
                        status: 500,
                        data: 'Internal Server Error',
                    };
                }
                return {
                    status: error.response.status,
                    data: error.response.data.error,
                };
            });
    };

    performPutRequest = (wr, url, payload, config = {}) => {
        return getInstance(wr)
            .put(url, payload, config)
            .then((response) => {
                return {
                    status: response.status,
                    data: response.data,
                };
            })
            .catch((error) => {
                if (error.message && error.message === 'Network Error') {
                    return {
                        status: 503,
                        data: error.message,
                    };
                } else if (!error || !error.response) {
                    return {
                        status: 500,
                        data: 'Internal Server Error',
                    };
                }
                return {
                    status: error.response.status,
                    data: error.response.data.error,
                };
            });
    };

    performGetRequest = (wr, url, config = {}) => {
        return getInstance(wr)
            .get(url, config)
            .then((response) => {
                return {
                    status: response.status,
                    data: response.data,
                };
            })
            .catch((error) => {
                if (!error || !error.response) {
                    return {
                        status: 500,
                    };
                }

                return {
                    status: error.response.status,
                };
            });
    };

    performGetRequestNewServer = (url, config = {}) => {
        return getInstanceNewApi().get(url, config);
    };
}
